"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BitMEXFeedhandler = void 0;
const instruments_1 = require("../instruments");
const L2Book_1 = require("../L2Book");
const Feedhandler_1 = require("./Feedhandler");
class BitMEXFeedhandler extends Feedhandler_1.FeedHandler {
    constructor() {
        super('BitMEX', 'wss://ws.bitmex.com/realtime');
        this._instruments = new instruments_1.InstrumentRepository();
    }
    onOpen(event) {
        this.ws().send(JSON.stringify({
            'op': 'subscribe',
            'args': [`orderBookL2:${this.getSymbol()}`, `trade:${this.getSymbol()}`]
        }));
    }
    onMessage(event) {
        const data = JSON.parse(event.data.toString());
        if (data.table === 'orderBookL2') {
            return this.handleOrderBookEvent(data);
        }
        if (data.table === 'trade') {
            return this.handleTradeEvent(data);
        }
    }
    handleOrderBookEvent(data) {
        const action = data.action;
        const bids = [];
        const asks = [];
        const instrument = this._instruments.getExchangeInstrument(this.getExchange(), this.getSymbol());
        for (const level of data.data) {
            const side = level.side == 'Buy' ? L2Book_1.Side.Buy : L2Book_1.Side.Sell;
            const price = level.price;
            const size = action === 'delete' ? 0 : level.size;
            side === L2Book_1.Side.Buy ? bids.push([price, size]) : asks.push([price, size]);
        }
        const event = {
            action: action === 'partial' ? L2Book_1.OrderBookAction.Partial : L2Book_1.OrderBookAction.Update,
            bids: bids,
            asks: asks
        };
        this.publishOrderBookEvent(event);
    }
    handleTradeEvent(data) {
        for (const t of data.data) {
            const trade = {
                price: t.price,
                size: t.size,
                side: t.side === 'Buy' ? L2Book_1.Side.Buy : L2Book_1.Side.Sell
            };
            this.publishTradeEvent(trade);
        }
    }
}
exports.BitMEXFeedhandler = BitMEXFeedhandler;
