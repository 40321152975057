"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const L2Book_1 = require("../L2Book");
const Feedhandler_1 = require("./Feedhandler");
class BinanceFeedHandler extends Feedhandler_1.FeedHandler {
    constructor() {
        super('Binance', 'wss://stream.binance.com:9443/stream');
        this._msgBuffer = [];
        this._hasReceivedSOW = false;
    }
    resetBuffer() {
        this._msgBuffer = [];
    }
    getMessagesAfterUpdateId(lastUpdateId) {
        // From https://binance-docs.github.io/apidocs/spot/en/#diff-depth-stream
        const idx = this._msgBuffer.findIndex((x) => {
            const { U, u } = x;
            return U <= lastUpdateId + 1 && u >= lastUpdateId + 1;
        });
        if (idx == -1) {
            return [];
        }
        return this._msgBuffer.slice(idx);
    }
    onOpen(event) {
        // Clear out state structures
        this.resetBuffer();
        // Reset SOW flag
        this._hasReceivedSOW = false;
        // Subscribe to each symbol
        const payload = JSON.stringify({
            id: 1,
            method: "SUBSCRIBE",
            params: [
                `${this.getSymbol().toLowerCase()}@depth@100ms`,
                `${this.getSymbol().toLowerCase()}@trade`
            ]
        });
        this.ws().send(payload);
        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`https://api.binance.com/api/v3/depth?symbol=${this.getSymbol()}&limit=5000`);
            const data = yield response.json();
            const partial = {
                action: L2Book_1.OrderBookAction.Partial,
                bids: data.bids.map((x) => [Number.parseFloat(x[0]), Number.parseFloat(x[1])]),
                asks: data.asks.map((x) => [Number.parseFloat(x[0]), Number.parseFloat(x[1])])
            };
            this.publishOrderBookEvent(partial);
            const lastUpdateId = data.lastUpdateId;
            const msgs = this.getMessagesAfterUpdateId(lastUpdateId);
            if (msgs.length > 0) {
                for (const m of msgs) {
                    this.publishOrderBookEvent(this.processDepthUpdateMessage(m));
                }
            }
            this._hasReceivedSOW = true;
        }), 1000);
    }
    processDepthUpdateMessage(msg) {
        return {
            action: L2Book_1.OrderBookAction.Update,
            bids: msg.b.map((x) => [Number.parseFloat(x[0]), Number.parseFloat(x[1])]),
            asks: msg.a.map((x) => [Number.parseFloat(x[0]), Number.parseFloat(x[1])])
        };
    }
    handleTradeMessage(msg) {
        if (!this._hasReceivedSOW)
            return;
        this.publishTradeEvent({
            price: parseFloat(msg.data.p),
            size: parseFloat(msg.data.q),
            side: msg.data.m ? L2Book_1.Side.Sell : L2Book_1.Side.Buy // I think thats how you do it?
        });
    }
    handleDepthUpdateMessage(msg) {
        const data = msg.data;
        if (this._hasReceivedSOW) {
            this.publishOrderBookEvent(this.processDepthUpdateMessage(data));
        }
        else {
            this._msgBuffer.push(data);
        }
    }
    onMessage(event) {
        const msg = JSON.parse(event.data);
        if (!('stream' in msg))
            return;
        if (msg.data.e === 'depthUpdate') {
            this.handleDepthUpdateMessage(msg);
        }
        if (msg.data.e === 'trade') {
            this.handleTradeMessage(msg);
        }
    }
}
exports.default = BinanceFeedHandler;
