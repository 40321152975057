"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.L2Book = exports.OrderBookAction = exports.Side = void 0;
var Side;
(function (Side) {
    Side["Buy"] = "buy";
    Side["Sell"] = "sell";
})(Side = exports.Side || (exports.Side = {}));
var OrderBookAction;
(function (OrderBookAction) {
    OrderBookAction[OrderBookAction["Partial"] = 0] = "Partial";
    OrderBookAction[OrderBookAction["Update"] = 1] = "Update";
})(OrderBookAction = exports.OrderBookAction || (exports.OrderBookAction = {}));
class L2Book {
    constructor() {
        this._bids = new Map();
        this._asks = new Map();
    }
    add(side, price, size) {
        const map = side === Side.Buy ? this._bids : this._asks;
        if (size === 0) {
            map.delete(price);
        }
        else {
            map.set(price, size);
        }
    }
    applyOrderBookEvent(event) {
        if (event.action === OrderBookAction.Partial) {
            this.clear();
        }
        event.bids.forEach((x) => this.add(Side.Buy, ...x));
        event.asks.forEach((x) => this.add(Side.Sell, ...x));
    }
    getBids() {
        const arr = [...this._bids.entries()];
        arr.sort((a, b) => b[0] - a[0]);
        return arr;
    }
    getAsks() {
        const arr = [...this._asks.entries()];
        arr.sort((a, b) => a[0] - b[0]);
        return arr;
    }
    getBidsMap() {
        return this._bids;
    }
    getAsksMap() {
        return this._asks;
    }
    clear() {
        this._bids.clear();
        this._asks.clear();
    }
}
exports.L2Book = L2Book;
