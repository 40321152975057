"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const L2Book_1 = require("../L2Book");
const Feedhandler_1 = require("./Feedhandler");
class KrakenFeedHandler extends Feedhandler_1.FeedHandler {
    constructor() {
        super('Kraken', 'wss://ws.kraken.com');
    }
    onOpen(event) {
        const subscribeBook = {
            event: 'subscribe',
            subscription: {
                name: 'book',
                depth: 1000,
            },
            pair: [this.getSymbol()]
        };
        this.ws().send(JSON.stringify(subscribeBook));
        const subscribeTrades = {
            event: 'subscribe',
            subscription: {
                name: 'trade',
            },
            pair: [this.getSymbol()]
        };
        this.ws().send(JSON.stringify(subscribeTrades));
    }
    onMessage(event) {
        const msg = JSON.parse(event.data);
        if (!Array.isArray(msg)) {
            return;
        }
        const [reqIds, data, typ, pair] = msg;
        if (`${typ}`.startsWith('book-')) {
            this.handleOrderBookEvent(data);
        }
        if (typ === "trade") {
            this.handleTradeEvent(data);
        }
    }
    handleOrderBookEvent(data) {
        var _a, _b, _c, _d;
        const isSnapshot = "as" in data || "bs" in data;
        const obSnapshotEvent = {
            action: isSnapshot ? L2Book_1.OrderBookAction.Partial : L2Book_1.OrderBookAction.Update,
            bids: ((_b = (_a = data.b) !== null && _a !== void 0 ? _a : data.bs) !== null && _b !== void 0 ? _b : []).map((x) => [Number.parseFloat(x[0]), Number.parseFloat(x[1])]),
            asks: ((_d = (_c = data.a) !== null && _c !== void 0 ? _c : data.as) !== null && _d !== void 0 ? _d : []).map((x) => [Number.parseFloat(x[0]), Number.parseFloat(x[1])])
        };
        this.publishOrderBookEvent(obSnapshotEvent);
    }
    handleTradeEvent(data) {
        data.forEach(d => {
            const trade = {
                price: Number.parseFloat(d[0]),
                size: Number.parseFloat(d[1]),
                side: d[3] === 'b' ? L2Book_1.Side.Buy : L2Book_1.Side.Sell
            };
            this.publishTradeEvent(trade);
        });
    }
}
exports.default = KrakenFeedHandler;
