"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeIndicator = void 0;
const three_1 = require("three");
class TradeIndicator {
    constructor(scene, price, size, side, radius) {
        this._price = price;
        this._size = size;
        this._side = side;
        this._scene = scene;
        const geometry = new three_1.SphereGeometry(radius, 16, 8);
        const material = new three_1.MeshLambertMaterial({ color: 0xebcf34 });
        this._mesh = new three_1.Mesh(geometry, material);
        this._scene.add(this._mesh);
    }
    destroy() {
        this._scene.remove(this._mesh);
        this._mesh.geometry.dispose();
        this._mesh.material.dispose();
    }
    setPosition(x, y, z) {
        this._mesh.position.x = x;
        this._mesh.position.y = y;
        this._mesh.position.z = z;
    }
    getPrice() {
        return this._price;
    }
}
exports.TradeIndicator = TradeIndicator;
