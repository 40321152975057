"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeedHandler = void 0;
class FeedHandler {
    constructor(exchange, wsUrl) {
        this._orderBookEventHandlers = [];
        this._tradeEventHandlers = [];
        this._url = wsUrl;
        this._exchange = exchange;
        this._connected = false;
        this._symbol = '';
    }
    ws() {
        if (this._ws === undefined) {
            throw new Error('WebSocket must be first initiated using connect()');
        }
        return this._ws;
    }
    // @override
    onOpen(event) {
        throw Error('Not yet implemented');
    }
    // @override
    onMessage(event) {
        throw Error('Not yet implemented');
    }
    onOrderBookEvent(fn) {
        this._orderBookEventHandlers.push(fn);
    }
    onTradeEvent(fn) {
        this._tradeEventHandlers.push(fn);
    }
    getExchange() {
        return this._exchange;
    }
    getSymbol() {
        return this._symbol;
    }
    connect(symbol) {
        console.log(`Connecting to ${this._exchange} WebSocket feed`);
        this._ws = new WebSocket(this._url);
        this._symbol = symbol;
        this.ws().onopen = (event) => {
            this.onOpen(event);
            this._connected = true;
        };
        this.ws().onmessage = (e) => {
            if (!this._connected)
                return;
            this.onMessage(e);
        };
    }
    disconnect() {
        if (this._connected === false)
            return;
        console.log(`Closing WebSocket connection to ${this._exchange}`);
        this.ws().close();
        this._ws = undefined;
        this._connected = false;
    }
    publishOrderBookEvent(event) {
        this._orderBookEventHandlers.forEach((fn) => fn(event));
    }
    publishTradeEvent(event) {
        this._tradeEventHandlers.forEach((fn) => fn(event));
    }
}
exports.FeedHandler = FeedHandler;
