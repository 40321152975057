"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGUI = void 0;
const vue_1 = require("vue");
const BookAnimation_1 = require("./BookAnimation");
function createGUI(instrumentRepository, feedManager, animation, book, initalExchange, initialSymbol) {
    const gui = (0, vue_1.createApp)({
        data() {
            return {
                expanded: true,
                exchanges: instrumentRepository.getExchanges(),
                currentExchange: initalExchange,
                symbols: instrumentRepository.getExchangeInstruments(initalExchange).map((ins) => ins.symbol),
                currentSymbol: initialSymbol,
                isCumulative: true,
                cameraModes: [BookAnimation_1.CameraMode.Front, BookAnimation_1.CameraMode.XWing, BookAnimation_1.CameraMode.FPS],
                currentCameraMode: BookAnimation_1.CameraMode.Front
            };
        },
        watch: {
            currentExchange(newExchange) {
                this.symbols = instrumentRepository.getExchangeInstruments(newExchange).map((ins) => ins.symbol);
                this.currentSymbol = this.symbols[0];
            },
            currentSymbol(newSymbol) {
                const ins = instrumentRepository.getExchangeInstrument(this.currentExchange, newSymbol);
                // When symbol is switched,
                feedManager.disconnect();
                book.clear();
                animation.reset();
                animation.draw();
                animation.setTickSize(ins.tickSize);
                feedManager.connect(this.currentExchange, newSymbol);
            },
            isCumulative(newCumulative) {
                animation.setCumulative(newCumulative);
            },
            currentCameraMode(newCameraMode) {
                animation.setCameraMode(newCameraMode);
            }
        },
        methods: {
            expand() {
                this.expanded = true;
            },
            close() {
                this.expanded = false;
            }
        }
    });
    return gui;
}
exports.createGUI = createGUI;
